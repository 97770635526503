const request = {
  data() {
    return {
      searchForm: {},
      dataList: [],
      page: {
        size: 10,
        current: 1,
        total: 0,
      },
      reqFunc: null,
      searchLoding: false,
      isLoading: false,
    };
  },
  methods: {
    onReset() {
      this.searchForm = {};
      this.page.current = 1;
      this.getData();
    },
    onSearch() {
      this.page.current = 1;
      this.getData();
      this.searchLoding = true;
    },

    getData(fun) {
      fun ??= this.reqFunc;
      this.reqFunc = fun;
      let search = this.searchForm;
      if (this.beforeSearch)
        search = this.beforeSearch(JSON.parse(JSON.stringify(this.searchForm)));

      this.isLoading = true;
      fun?.({
        data: { ...search },
        pageNumber: this.page.current,
        pageSize: this.page.size,
      })
        .then((res) => {
          this.page.total = +res.data.total;
          this.dataList = res.data.content || [];
          this.searchLoding = false;
          this.isLoading = false;
        })
        .catch((e) => {
          this.isLoading = false;
          this.searchLoding = false;
        });
    },
    handleSizeChange(val) {
      this.page.size = val;
      this.getData();
    },
    handleCurrentChange(val) {
      this.page.current = val;
      this.getData();
    },
  },
};

export default request;
