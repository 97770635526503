<template>
  <div class="interest-list">
    <el-form inline :model="searchForm" size="small" label-width="90px">
      <el-form-item label="权益名称">
        <el-input v-model="searchForm.rightsName" placeholder="请输入权益名称"></el-input>
      </el-form-item>
      <el-form-item label="权益ID">
        <el-input v-model="searchForm.rightsId" placeholder="请输入权益ID"></el-input>
      </el-form-item>
      <el-form-item label="权益类型">
        <el-select v-model="searchForm.rightsCategoryId" placeholder="请选择" style="width: 200px">
          <el-option
            v-for="(item, index) in rightsList"
            :key="index"
            :label="item.rightsCategoryName"
            :value="item.rightsCategoryId"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="优惠类型">
        <el-select
          v-model="searchForm.discountCategoryId"
          placeholder="请选择"
          style="width: 200px"
        >
          <el-option
            v-for="(item, index) in discountCategoryList"
            :key="index"
            :label="item.rightsCategoryName"
            :value="item.rightsCategoryId"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="状态">
        <el-select v-model="searchForm.isDisable" placeholder="请选择" style="width: 200px">
          <el-option label="启用" :value="0"></el-option>
          <el-option label="停用" :value="1"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" icon="el-icon-search" :loading="searchLoding" @click="onSearch"
          >搜 索</el-button
        >
        <el-button icon="el-icon-refresh-right" @click="onReset">重 置</el-button>
      </el-form-item>
    </el-form>
    <el-button type="primary" icon="el-icon-plus" size="small" @click="goAdd">新 增</el-button>
    <el-table border class="interest-table" :data="dataList">
      <el-table-column label="权益名称" prop="rightsName" width="200px">
        <template slot-scope="scope">
          <el-button type="text" @click="onDetail(scope.row)">{{ scope.row.rightsName }}</el-button>
        </template>
      </el-table-column>
      <el-table-column label="ID" prop="rightsId" width="100px"></el-table-column>
      <el-table-column label="权益类别" prop="rightsCategoryName"></el-table-column>
      <el-table-column label="优惠类型" prop="discountCategoryName"></el-table-column>
      <el-table-column
        label="权益内容"
        prop="rightsContent"
        show-overflow-tooltip
      ></el-table-column>

      <el-table-column label="状态" fixed="right" width="100px">
        <template slot-scope="scope">
          <div :class="scope.row.isDisable == 1 ? 'status' : 'status--use'">
            {{ scope.row.isDisable == 1 ? '停用' : '启用' }}
          </div>
        </template>
      </el-table-column>
      <el-table-column label="操作" fixed="right" width="100px">
        <template slot-scope="scope">
          <el-button type="text" @click="onStatus(scope.row)">{{
            scope.row.isDisable == 1 ? '启用' : '停用'
          }}</el-button>
          <el-button v-show="scope.row.isDisable == 1" type="text" @click="onEdit(scope.row)"
            >编辑</el-button
          >
        </template>
      </el-table-column>
    </el-table>

    <div>
      <el-pagination
        :current-page="page.current"
        :page-sizes="[10, 30, 50, 100]"
        :page-size="page.size"
        background
        layout="total, sizes, prev, pager, next, jumper"
        :total="page.total"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
      >
      </el-pagination>
    </div>
  </div>
</template>

<script>
import request from '@/mixins/request.js'
import { getInterestList, findListByCondition, updateDisableById } from '@/api/interest.js'
export default {
  mixins: [request],
  data() {
    return {
      rightsList: [],
      discountCategoryList: [],
    }
  },
  mounted() {
    this.getData(getInterestList)
    this.getOptions(1).then((res) => (this.rightsList = res.data))
    this.getOptions(2).then((res) => (this.discountCategoryList = res.data))
  },
  methods: {
    async getOptions(rightsCategoryTypeId) {
      return await findListByCondition({
        data: {
          rightsCategoryTypeId,
          isDisable: 0,
        },
      })
    },
    goAdd() {
      this.$router.push({
        name: 'interestDetail',
      })
    },
    onEdit(row) {
      this.$router.push({
        name: 'interestDetail',
        query: {
          rightsId: row.rightsId,
        },
      })
    },
    onDetail(row) {
      this.$router.push({
        name: 'interestDetail',
        query: {
          rightsId: row.rightsId,
          disabled: 1,
        },
      })
    },
    onStatus(row) {
      updateDisableById({
        data: {
          rightsId: row.rightsId,
          isDisable: row.isDisable === 1 ? 0 : 1,
        },
      }).then((res) => {
        console.log(res)
        this.getData()
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.status {
  color: #333333;
  font-size: 14px;
  font-weight: 400;
  display: flex;
  align-items: center;
}
.status--use {
  color: #333333;
  font-size: 14px;
  font-weight: 400;
  display: flex;
  align-items: center;
}
.status::before {
  content: '';
  width: 6px;
  height: 6px;
  background-color: #9a9d9e;
  border-radius: 50%;
  margin-right: 4px;
}
.status--use::before {
  content: '';
  width: 6px;
  height: 6px;
  background-color: #00a199;
  border-radius: 50%;
  margin-right: 4px;
}
.interest-list {
  background-color: #fff;
  padding: 20px;
}
.interest-table {
  margin-top: 20px;
}
</style>
