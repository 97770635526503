import request from "@/utils/request";

export function getInterestList(data) {
  return request({
    url: "/marketing-web-api/admin/rights/pageList",
    method: "POST",
    data,
  });
}

export function findListByCondition(data) {
  return request({
    url: "/marketing-web-api/admin/rights/category/findListByCondition",
    method: "POST",
    data,
  });
}

export function interestAdd(data) {
  return request({
    url: "/marketing-web-api/admin/rights/add",
    method: "POST",
    data,
  });
}

export function findInterestById(data) {
  return request({
    url: "/marketing-web-api/admin/rights/findById",
    method: "POST",
    data,
  });
}

export function interestUpdateById(data) {
  return request({
    url: "/marketing-web-api/admin/rights/updateById",
    method: "POST",
    data,
  });
}

export function updateDisableById(data) {
  return request({
    url: "/marketing-web-api/admin/rights/updateDisableById",
    method: "POST",
    data,
  });
}
